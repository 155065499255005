export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig().public
  const { token } = useAuth()
  const route = useRoute()
  const router = useRouter()

  const api = $fetch.create({
    baseURL: config.apiUrl,
    // ignoreResponseError: true,
    onRequest ({ options }) {
      options?.headers?.set('Authorization', `Bearer ${token.value}`)
    },
    onResponseError ({ response }) {
      // console.log(context)
      if (response.status === 401) {
        router.push(`/admin/auth/login?from=${route.fullPath}`)
        nuxtApp.runWithContext(() => nuxtApp.$bus.emit('auth:unauthorized'))
        throw new Error('unauthorized')
      }

      // return { response: { oeps: 'ja' } }
    }
  })

  return {
    provide: {
      api
    }
  }
})
