import { default as _91_46_46_46slug_93i42GoiidIXMeta } from "/var/lib/jenkins/workspace/Master/pages/[...slug].vue?macro=true";
import { default as index3t6ZEdEWCmMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/account/index.vue?macro=true";
import { default as loginfdTvLJBCsGMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/auth/login.vue?macro=true";
import { default as _91id_93VnLuc9xIdlMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/categories/[id].vue?macro=true";
import { default as indexC5bMqwqRpBMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/categories/index.vue?macro=true";
import { default as newexT9ulJM9zMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/categories/new.vue?macro=true";
import { default as _91id_93gprfL1PIC9Meta } from "/var/lib/jenkins/workspace/Master/pages/admin/colors/[id].vue?macro=true";
import { default as indexOtRJw7wtivMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/colors/index.vue?macro=true";
import { default as newSkv7sKD27jMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/colors/new.vue?macro=true";
import { default as indexkI5rS4HVptMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/index.vue?macro=true";
import { default as _91id_93LNY5uj3oYWMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/locations/[id].vue?macro=true";
import { default as indexa2ruqqvqJvMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/locations/index.vue?macro=true";
import { default as newZxaVhvD49CMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/locations/new.vue?macro=true";
import { default as _91id_939SvB8IUZqhMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/products/[id].vue?macro=true";
import { default as indexgXPCb707hiMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/products/index.vue?macro=true";
import { default as new0OQOBO1QHqMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/products/new.vue?macro=true";
import { default as _91id_935oDZzwF0GxMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/themes/[id].vue?macro=true";
import { default as indexhpGO4ajQh4Meta } from "/var/lib/jenkins/workspace/Master/pages/admin/themes/index.vue?macro=true";
import { default as newz4a8Yuny8qMeta } from "/var/lib/jenkins/workspace/Master/pages/admin/themes/new.vue?macro=true";
import { default as adminkZnekNuHDZMeta } from "/var/lib/jenkins/workspace/Master/pages/admin.vue?macro=true";
import { default as pers_45kittrD6eMfz1IMeta } from "/var/lib/jenkins/workspace/Master/pages/pers-kit.vue?macro=true";
import { default as quotemakerykjTXON8PwMeta } from "/var/lib/jenkins/workspace/Master/pages/quotemaker.vue?macro=true";
import { default as social_45mediaCP6WRVfzG7Meta } from "/var/lib/jenkins/workspace/Master/pages/social-media.vue?macro=true";
import { default as styleguideMYX88DdOE9Meta } from "/var/lib/jenkins/workspace/Master/pages/styleguide.vue?macro=true";
import { default as _91id_93TOOvINhg8PMeta } from "/var/lib/jenkins/workspace/Master/pages/verhuur-nieuw/[id].vue?macro=true";
import { default as indexQFDU5j2tldMeta } from "/var/lib/jenkins/workspace/Master/pages/verhuur-nieuw/index.vue?macro=true";
import { default as _91id_93wAqFt20hpfMeta } from "/var/lib/jenkins/workspace/Master/pages/verhuur-nieuw/product/[slug]/[id].vue?macro=true";
import { default as component_45stubN5r1RucVicMeta } from "/var/lib/jenkins/workspace/Master/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubN5r1RucVic } from "/var/lib/jenkins/workspace/Master/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/[...slug].vue")
  },
  {
    name: adminkZnekNuHDZMeta?.name,
    path: "/admin",
    meta: adminkZnekNuHDZMeta || {},
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin.vue"),
    children: [
  {
    name: "admin-account",
    path: "account",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/account/index.vue")
  },
  {
    name: "admin-auth-login",
    path: "auth/login",
    meta: loginfdTvLJBCsGMeta || {},
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/auth/login.vue")
  },
  {
    name: "admin-categories-id",
    path: "categories/:id()",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/categories/[id].vue")
  },
  {
    name: "admin-categories",
    path: "categories",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/categories/index.vue")
  },
  {
    name: "admin-categories-new",
    path: "categories/new",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/categories/new.vue")
  },
  {
    name: "admin-colors-id",
    path: "colors/:id()",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/colors/[id].vue")
  },
  {
    name: "admin-colors",
    path: "colors",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/colors/index.vue")
  },
  {
    name: "admin-colors-new",
    path: "colors/new",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/colors/new.vue")
  },
  {
    name: "admin",
    path: "",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/index.vue")
  },
  {
    name: "admin-locations-id",
    path: "locations/:id()",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/locations/[id].vue")
  },
  {
    name: "admin-locations",
    path: "locations",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/locations/index.vue")
  },
  {
    name: "admin-locations-new",
    path: "locations/new",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/locations/new.vue")
  },
  {
    name: "admin-products-id",
    path: "products/:id()",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/products/[id].vue")
  },
  {
    name: "admin-products",
    path: "products",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/products/index.vue")
  },
  {
    name: "admin-products-new",
    path: "products/new",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/products/new.vue")
  },
  {
    name: "admin-themes-id",
    path: "themes/:id()",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/themes/[id].vue")
  },
  {
    name: "admin-themes",
    path: "themes",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/themes/index.vue")
  },
  {
    name: "admin-themes-new",
    path: "themes/new",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/admin/themes/new.vue")
  }
]
  },
  {
    name: "pers-kit",
    path: "/pers-kit",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/pers-kit.vue")
  },
  {
    name: "quotemaker",
    path: "/quotemaker",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/quotemaker.vue")
  },
  {
    name: "social-media",
    path: "/social-media",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/social-media.vue")
  },
  {
    name: "styleguide",
    path: "/styleguide",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/styleguide.vue")
  },
  {
    name: "verhuur-nieuw-id",
    path: "/verhuur-nieuw/:id()",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/verhuur-nieuw/[id].vue")
  },
  {
    name: "verhuur-nieuw",
    path: "/verhuur-nieuw",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/verhuur-nieuw/index.vue")
  },
  {
    name: "verhuur-nieuw-product-slug-id",
    path: "/verhuur-nieuw/product/:slug()/:id()",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/verhuur-nieuw/product/[slug]/[id].vue")
  },
  {
    name: component_45stubN5r1RucVicMeta?.name,
    path: "/verhuurfolder",
    component: component_45stubN5r1RucVic
  },
  {
    name: component_45stubN5r1RucVicMeta?.name,
    path: "/filmpjes",
    component: component_45stubN5r1RucVic
  },
  {
    name: component_45stubN5r1RucVicMeta?.name,
    path: "/sponsoren",
    component: component_45stubN5r1RucVic
  },
  {
    name: component_45stubN5r1RucVicMeta?.name,
    path: "/videos-2023",
    component: component_45stubN5r1RucVic
  }
]