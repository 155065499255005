import { useStorage, StorageSerializers } from '@vueuse/core'
export const useAuth = () => {
  const token = useStorage('auth_token', null)
  const expires = useStorage('auth_expires', null)
  const user = useStorage('auth_user', null, undefined, { serializer: StorageSerializers.object })

  const isExpired = computed(() => {
    if (import.meta.server) { return false }
    const expiredDate = Date.parse(expires.value)
    return expiredDate > Date.now()
  })

  const login = async (email: string, password: string) => {
    if (!email || !password) { throw new Error('email or password not given') }

    const { data, error } = await useApi('auth/login', {
      method: 'POST',
      body: {
        email,
        password
      }
    })

    if (error.value) {
      throw new Error(error.value.data)
    }

    if (data.value) {
      token.value = data.value.token
      expires.value = data.value.expires
      user.value = data.value.user
    }
    return data
  }

  const logout = () => {
    token.value = null
    user.value = null
  }

  return {
    token,
    expires,
    user,
    login,
    logout,
    isExpired
  }
}
